import Title from '~/components/shared/Title'
import { useAddClassNameWhenVisible } from '~/hooks/useAddClassNameWhenVisible'
import * as containerStylesSpriteIcons from '~/styles/modules/SpriteIcons.module.scss'
import { DataContentProps, WithOptional } from '~/types/helper'
import { ExpertiseSectionMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './SpecificationBlockWithIcon.module.scss'

interface SpecificationBlockWithIconProps
  extends DataContentProps<WithOptional<ExpertiseSectionMock, 'icon'>> {
  isTwoColumn?: boolean
}

const SpecificationBlockWithIcon = ({
  dataContent,
  isTwoColumn,
}: SpecificationBlockWithIconProps) => (
  <div className="container container-md">
    <div className="row">
      {dataContent.map(({ icon, link, title, description }) => {
        const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
          targetClassName:
            containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
          loadedClassName:
            containerStylesSpriteIcons.spriteIconsWrapper__sprite,
        })

        return (
          <div
            ref={ref}
            key={title}
            className={`${isTwoColumn ? 'col-12 col-md-6' : 'col-12 col-md-4'}`}
          >
            <div className={containerStyles.specificationBlockWithIcon__item}>
              {icon && (
                <div
                  className={cx(
                    containerStyles.specificationBlockWithIcon__icon,
                    containerStylesSpriteIcons.spriteIconsWrapper,
                    containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
                  )}
                />
              )}

              <Title
                link={link}
                headingLevel="h3"
                className={containerStyles.specificationBlockWithIcon__title}
              >
                {title}
              </Title>

              <p
                className={
                  containerStyles.specificationBlockWithIcon__description
                }
              >
                {description}
              </p>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default SpecificationBlockWithIcon
