import { useAddClassNameAfterMount } from '~/hooks/useAddClassNameAfterMount'
import * as styles3DIcons from '~/styles/modules/Sprite3DIcons.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './SpecificationBlockIconTitle.module.scss'

const SpecificationBlockIconTitle = ({ dataContent }) => {
  const ref = useAddClassNameAfterMount<HTMLDivElement>({
    targetClassName: styles3DIcons.sprite3DIcons,
    loadedClassName: styles3DIcons.sprite3DIcons__sprite,
  })

  return (
    <div className="container container-md">
      <div ref={ref} className="row">
        {dataContent.map(({ icon, title }) => (
          <div key={title} className="col-6 col-lg-3">
            <div
              className={cx(
                containerStyles.specificationBlockIconTitle__icon,
                styles3DIcons.sprite3DIcons,
                styles3DIcons[`sprite3DIcons__${icon}`],
              )}
            />

            <h3 className={containerStyles.specificationBlockIconTitle__title}>
              {title}
            </h3>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SpecificationBlockIconTitle
