import { Link } from 'gatsby'

import { cx } from '~/utils'

import * as containerStyles from './CallToAction.module.scss'

interface CallToActionProps {
  link: string
  title: string
  subtitle?: string
  linkName: string
  maxWidthTitle?: string
  classNameForGA?: string
}

const CallToAction = ({
  link,
  title,
  subtitle,
  linkName,
  maxWidthTitle,
  classNameForGA,
}: CallToActionProps) => (
  <div className="container container-md">
    <div className="row">
      <div className="col-12">
        <div className={containerStyles.callToAction}>
          <h3
            className={containerStyles.callToAction__title}
            style={{ maxWidth: maxWidthTitle }}
          >
            {title}
          </h3>
          {subtitle && (
            <p className={containerStyles.callToAction__subtitle}>{subtitle}</p>
          )}
          <Link
            to={link}
            className={cx(containerStyles.callToAction__link, classNameForGA)}
          >
            {linkName}
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default CallToAction
