import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const usePrimeCompanyStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      clutchLogo: file(
        relativePath: { eq: "awards/icon-clutch-ror-2023.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      goodfirmsLogo: file(
        relativePath: { eq: "awards/icon-goodfirms-2019.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      manifestLogo: file(
        relativePath: { eq: "awards/icon-manifest-2021.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default usePrimeCompanyStaticQuery
