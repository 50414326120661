import { Link } from 'gatsby'

import HeadSection from '~/components/blocks/HeadSection'
import * as containerButtonStyles from '~/components/buttons/Button/Button.module.scss'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import { cx } from '~/utils'

import * as containerStyles from './CompanyCasesSliderSection.module.scss'

const CompanyCasesSliderSection = () => (
  <section className={containerStyles.companyCasesSection}>
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div className={containerStyles.companyCasesSection__topWrapper}>
            <HeadSection
              title="Some of the projects we delivered"
              titleFullWidth
            />

            <Link
              to="/case-studies/"
              className={cx(
                containerButtonStyles.button,
                containerButtonStyles.button__red,
                containerStyles.companyCasesSection__btn,
              )}
            >
              View all our works
            </Link>
          </div>
        </div>
      </div>
    </div>

    <SuccessStoriesSliderSection />
  </section>
)

export default CompanyCasesSliderSection
