import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import CompanyInfo from '~/components/blocks/CompanyInfo'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import MainLayout from '~/components/layouts/MainLayout'
import SpecificationBlockIconTitle from '~/components/lists/SpecificationBlockIconTitle'
import SpecificationBlockWithIcon from '~/components/lists/SpecificationBlockWithIcon'
import Leadership from '~/components/sections/Leadership'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import CompanyBusinesses from '~/mock/company/CompanyBusinesses'
import CompanyProperty from '~/mock/company/CompanyProperty'
import { fileToImageLikeData } from '~/utils'
import CompanyPrimeSection from '~/views/Company/components/CompanyPrimeSection'

import CompanyProjectsLogoSection from '../Homepage/components/CompanyProjectsLogoSection'
import OurCoreValuesSection from '../Homepage/components/OurCoreValuesSection'

import * as containerStyles from './Company.module.scss'
import CompanyCasesSliderSection from './components/CompanyCasesSliderSection'
import CompanyPostSliderSection from './components/CompanyPostSliderSection'
import CompanyQuotesSliderSection from './components/CompanyQuotesSliderSection'

const CompanyPage = () => {
  const query = useStaticQuery(graphql`
    query {
      reviewImage: file(
        relativePath: { eq: "avatars/clients/large-photo/client-pmk.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const reviewImage = getImage(fileToImageLikeData(query.reviewImage))

  return (
    <MainLayout isTransparent scrollTop>
      <CompanyPrimeSection />

      <section className={containerStyles.companyOurStorySection}>
        <HeadSection position="left" title="Our story" />
        <CompanyInfo
          isInfo
          description="Codica has been driven by passion and ambition. These qualities formed our philosophy and keep motivating us to craft outstanding software products. Through years of effort, we’ve amassed expertise to provide comprehensive product development services, knowing every stage of the lifecycle like the palm of our hand."
        />
      </section>

      <section className={containerStyles.companyLeadershipSection}>
        <HeadSection position="left" title="Leadership team" />
        <Leadership />
      </section>

      <OurCoreValuesSection />

      <section className={containerStyles.companyVideoSection}>
        <HeadSection position="left" title="What our clients say" />

        <div className="container container-md">
          <ClientVideoQuotes
            name="David Watkins"
            position="Founder of PlanMyKids"
            location="USA"
            image={reviewImage}
            quote={[
              {
                text: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family felt like it required a project management certification.”',
              },
            ]}
            link="kids-activities-service-marketplace"
            videoLink="https://www.youtube.com/embed/dzxMCDLghZQ"
          />
        </div>
      </section>

      <section className={containerStyles.companyBusinessesSection}>
        <HeadSection
          position="left"
          title="Our mission is to help businesses build successful web products"
          titleLarge
        />
        <SpecificationBlockWithIcon dataContent={CompanyBusinesses} />
      </section>

      <section className={containerStyles.companyCtaSection}>
        <CallToAction
          title="We create software that users love."
          subtitle="Become one of our successful clients!"
          classNameForGA="callToAction_AboutUs_1"
          linkName="Get in touch"
          link="/contacts/"
        />
      </section>

      <section className={containerStyles.companyPropertySection}>
        <HeadSection
          position="left"
          title="How we ensure your intellectual property"
          description="Codica is a trusted development partner for many businesses worldwide. Here’s how your intellectual property is protected when working with us."
          titleFullWidth
          descriptionLarge
        />
        <SpecificationBlockIconTitle dataContent={CompanyProperty} />
      </section>

      <CompanyCasesSliderSection />

      <section className={containerStyles.companyCtaSection}>
        <CallToAction
          title="With Codica, you don’t have to be a tech expert to launch a great web product."
          maxWidthTitle="750px"
          classNameForGA="callToAction_AboutUs_2"
          linkName="Start your next project with us!"
          link="/contacts/"
        />
      </section>

      <LazyHydrate whenVisible>
        <CompanyProjectsLogoSection />
      </LazyHydrate>

      <section className={containerStyles.companyQuotesSliderSection}>
        <CompanyQuotesSliderSection />
      </section>

      <section className={containerStyles.companyPostSliderSection}>
        <CompanyPostSliderSection />
      </section>

      <PrimeContactFormSection title="Let’s turn your idea into a successful product!" />
    </MainLayout>
  )
}

export default CompanyPage
