import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './CompanyPrimeSection.module.scss'
import useCompanyPrimeSectionStaticQuery from './useCompanyPrimeSectionStaticQuery'

const CompanyPrimeSection = () => {
  const query = useCompanyPrimeSectionStaticQuery()

  const clutchLogo = getImage(fileToImageLikeData(query.clutchLogo))
  const goodfirmsLogo = getImage(fileToImageLikeData(query.goodfirmsLogo))
  const manifestLogo = getImage(fileToImageLikeData(query.manifestLogo))

  const companyAwards = [
    {
      logo: clutchLogo,
      logoTitle: 'Clutch logo | Codica',
    },
    {
      logo: goodfirmsLogo,
      logoTitle: 'GoodFirms logo | Codica',
    },
    {
      logo: manifestLogo,
      logoTitle: 'Manifest logo | Codica',
    },
  ]

  return (
    <div className={containerStyles.companyPrimeSection}>
      <div className="container container-md">
        <div className="row">
          <div className="col-12">
            <div className={containerStyles.companyPrimeSection__wrapper}>
              <h1 className={containerStyles.companyPrimeSection__head}>
                We are a team of web development experts, passionate about
                technology and innovation.
              </h1>
              <p className={containerStyles.companyPrimeSection__subhead}>
                We specialize in custom online marketplaces and SaaS products,
                starting from MVP to full-featured product development.
              </p>
            </div>
          </div>
          {companyAwards.map(({ logo, logoTitle }) => (
            <div className="col-12 col-md-4" key={logoTitle}>
              <div className={containerStyles.companyPrimeSection__logoWrapper}>
                <GatsbyImage
                  image={logo as IGatsbyImageData}
                  alt={`${logoTitle} | Codica`}
                  title={logoTitle}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CompanyPrimeSection
