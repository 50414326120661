import { ExpertiseSectionMock } from '~/types/mock'

const CompanyProperty: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_11',
    title: 'Privacy protection with NDA',
  },
  {
    icon: 'sprite3DIcon_4',
    title: 'Design and code belong to you',
  },
  {
    icon: 'sprite3DIcon_7',
    title: 'Transparent agreements',
  },
  {
    icon: 'sprite3DIcon_22',
    title: 'Business ethics and reputation',
  },
]

export default CompanyProperty
