import { ExpertiseSectionMock } from '~/types/mock'

const CompanyBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_10',
    title: 'Save costs and launch quickly',
    description:
      'Our development process is efficient and fully transparent, allowing us to deliver your software product on time and on budget.',
  },
  {
    icon: 'spriteIcon_60',
    title: 'The right solution for your needs',
    description:
      'Clients’ success is our priority. We carefully align your business requirements with software functionality to ensure the best value.',
  },
  {
    icon: 'spriteIcon_42',
    title: 'Full control over product development',
    description:
      'We provide daily and weekly reports on the project progress, and clearly communicate any issues that may arise beforehand.',
  },
  {
    icon: 'spriteIcon_56',
    title: 'Enthusiastic team that cares',
    description:
      'We are proud of our result-oriented team that values communication, flexibility, and focuses on high-value relationships that last.',
  },
  {
    icon: 'spriteIcon_69',
    title: 'Best industry standards',
    description:
      'Having built numerous custom web applications, our experienced team offers the best solution for your business challenges.',
  },
  {
    icon: 'spriteIcon_98',
    title: 'Support after launch',
    description:
      'Our work doesn’t stop when your custom product goes live. Our reliable support team will ensure your platform runs smoothly.',
  },
]

export default CompanyBusinesses
